// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-case-js": () => import("./../../../src/templates/case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-templates-dynamic-page-js": () => import("./../../../src/templates/dynamic-page.js" /* webpackChunkName: "component---src-templates-dynamic-page-js" */),
  "component---src-templates-obrigado-js": () => import("./../../../src/templates/obrigado.js" /* webpackChunkName: "component---src-templates-obrigado-js" */),
  "component---src-templates-servicos-js": () => import("./../../../src/templates/servicos.js" /* webpackChunkName: "component---src-templates-servicos-js" */),
  "component---src-templates-solicitar-orcamento-js": () => import("./../../../src/templates/solicitar-orcamento.js" /* webpackChunkName: "component---src-templates-solicitar-orcamento-js" */),
  "component---src-templates-trabalhe-conosco-js": () => import("./../../../src/templates/trabalhe-conosco.js" /* webpackChunkName: "component---src-templates-trabalhe-conosco-js" */),
  "component---src-templates-x-apps-na-midia-js": () => import("./../../../src/templates/x-apps-na-midia.js" /* webpackChunkName: "component---src-templates-x-apps-na-midia-js" */)
}

